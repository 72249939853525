<script>
export default {
  name: 'LinkedInIcon',
  props: {
    employeeName: {
      type: String,
      required: true,
    },
  },
  methods: {
    captureCustomEvent() {
      this.$posthog.capture('linkedin_icon_clicked', {
        'employee': this.employeeName
      })
    }
  }
}
</script>

<template>
  <svg @click="captureCustomEvent" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
    <path d="M2 9h4v12H2z"></path>
    <path d="M4 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"></path>
  </svg>
</template>

<style lang="scss" scoped>

</style>
