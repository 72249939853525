<script setup>

</script>

<template>
  <svg width="16" height="16" fill="#E9E9E9" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2 3.6a1.2 1.2 0 1 0 0 2.4h3.103l-7.551 7.552a1.2 1.2 0 1 0 1.696 1.696L18 7.697V10.8a1.2 1.2 0 1 0 2.4 0v-6a1.2 1.2 0 0 0-1.2-1.2h-6Z"></path>
    <path d="M6 6a2.4 2.4 0 0 0-2.4 2.4V18A2.4 2.4 0 0 0 6 20.4h9.6A2.4 2.4 0 0 0 18 18v-3.6a1.2 1.2 0 1 0-2.4 0V18H6V8.4h3.6a1.2 1.2 0 1 0 0-2.4H6Z"></path>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  opacity: 0.8;
}
</style>
