import posthog from 'posthog-js'

// PostHog API key
const API_KEY = 'phc_jyD1hmmEMpg4tv6tQus7iULHG9bsqgpCNR1KcllWKfc'

// Create a stub for PostHog when not loaded or when user hasn't consented
const posthogStub = {
    capture: () => {},
    identify: () => {},
    opt_out_capturing: () => {},
    opt_in_capturing: () => {},
    init: () => posthogStub
}

// Create a variable to track if we've already initialized PostHog
let isInitialized = false

// Create a variable to store the PostHog instance
let posthogInstance = null

export default {
    install(app) {
        // Only initialize once to prevent refresh loops
        if (isInitialized) {
            console.log("[PostHog] Already initialized, using existing instance")

            // If we already have a PostHog instance, use it
            if (posthogInstance) {
                app.config.globalProperties.$posthog = posthogInstance
            } else {
                app.config.globalProperties.$posthog = posthogStub
            }
            return
        }

        console.log("[PostHog] Initializing for the first time")
        isInitialized = true

        try {
            // Safely check localStorage
            let userConsent = null
            try {
                userConsent = localStorage.getItem('posthog_user_consent')
                console.log("[PostHog] User consent:", userConsent)
            } catch (e) {
                console.error("[PostHog] Error accessing localStorage:", e)
                // Fall back to stub implementation if localStorage is inaccessible
                app.config.globalProperties.$posthog = posthogStub
                posthogInstance = posthogStub
                return
            }

            if (userConsent === 'accepted') {
                console.log("[PostHog] User has accepted tracking, initializing PostHog")
                try {
                    // Initialize PostHog with the API key and configuration
                    posthogInstance = posthog.init(API_KEY, {
                        api_host: 'https://us.i.posthog.com',
                        autocapture: true,
                        capture_pageview: true,
                        loaded: () => {
                            console.log("[PostHog] PostHog loaded successfully")
                        }
                    })
                    app.config.globalProperties.$posthog = posthogInstance
                    console.log("[PostHog] Successfully initialized")
                } catch (e) {
                    console.error("[PostHog] Error initializing PostHog:", e)
                    // Fall back to stub implementation if initialization fails
                    app.config.globalProperties.$posthog = posthogStub
                    posthogInstance = posthogStub
                }
            } else {
                console.log("[PostHog] User has not accepted tracking, using stub implementation")
                // Use stub implementation when user hasn't accepted tracking
                app.config.globalProperties.$posthog = posthogStub
                posthogInstance = posthogStub
            }
        } catch (e) {
            console.error("[PostHog] Unexpected error in PostHog plugin:", e)
            // Fall back to stub implementation if anything goes wrong
            app.config.globalProperties.$posthog = posthogStub
            posthogInstance = posthogStub
        }
    }
}
