<script>
export default {
  name: 'XIcon',
  props: {
    employeeName: {
      type: String,
      required: true,
    },
  },
  methods: {
    captureCustomEvent() {
      this.$posthog.capture('x_icon_clicked', {
        'employee': this.employeeName
      })
    }
  }
}
</script>

<template>
  <svg @click="captureCustomEvent" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round"
       stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22 4.009c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.322 12 7.999v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497-3.753c-.002-.249 1.51-2.772 1.818-4.013v.001Z"></path>
  </svg>
</template>

<style lang="scss" scoped>

</style>
