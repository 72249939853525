<script setup>

</script>

<template>
  <div class="content-wrapper">
    <div class="content">
      <h1>Terms and Conditions</h1>
      <h2>Introduction</h2>
      <p>By accessing this website, you agree to comply with and be bound by the following terms and conditions. If you
        disagree with any part of these terms, please do not use our website.</p>

      <h2>Intellectual Property Rights</h2>
      <p>Unless otherwise stated, we or our licensors own the intellectual property rights for all material on this
        website. All intellectual property rights are reserved. You may access this from our website for your own
        personal use, subject to restrictions set in these terms and conditions.</p>

      <h2>User Conduct</h2>
      <p>You must not:</p>
      <ul>
        <li>Republish material from this website.</li>
        <li>Sell, rent, or sub-license material from this website.</li>
        <li>Reproduce, duplicate, or copy material from this website.</li>
        <li>Redistribute content from this website.</li>
      </ul>

      <h2>Limitation of Liability</h2>
      <p>We will not be liable for any loss or damage of any nature arising out of or in connection with your use of the
        website.</p>

      <h2>Changes to These Terms</h2>
      <p>We may revise these terms and conditions from time to time. By continuing to use the website after any changes
        are made, you accept the new terms and conditions.</p>

      <h2>Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of the Netherlands, and you
        irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hero {
  &-title {
    font-size: 56px;
  }

  &-wrapper {
    margin-top: 48px;
    position: relative;
    z-index: 1;
  }
}

.content {
  max-width: 800px;
  text-align: start;
  display: flex;
  flex-direction: column;

  &-wrapper {
    padding: 28px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin-top: 16px;
    }

    p, ul {
      margin-bottom: 16px;
    }

    ul {
      margin-left: 20px;
    }
  }
}
</style>
